import Layout from "@/layout/Layout";
import Login from "@/pages/authentication/login";
import Register from "@/pages/authentication/register";
import Landing from "@/pages/landing";
import { lazy, ReactNode, Suspense } from "react";

const Home = lazy(() => import("../pages/home/home"));
const Editor = lazy(() => import("../pages/editor/editor"));
const FormResults = lazy(() => import("../pages/formResults/formResults"));

/**
 * Note: The `selectedFormId` parameter is special and is used to dynamically
 * determine the form being accessed in the application. When a route with
 * `:selectedFormId` is accessed, the application will automatically update
 * the selected form ID in the Redux store, allowing components to react to
 * the currently selected form. This is happening in `form-id-watcher.tsx`
 */
export const PATHS = {
  LANDING: "/",
  REGISTER: "/register",
  LOGIN: "/login",
  HOME: "/app",
  CREATE: "/app/form/:selectedFormId/create",
  PREVIEW: "/app/form/:selectedFormId/preview",
  RESULTS: "/app/form/:selectedFormId/results",
  INTEGRATIONS: "/app/integrations",
};

export interface RouteConfig {
  path: string;
  element: ReactNode;
  children?: RouteConfig[];
}

export const PUBLIC_ROUTES: (keyof typeof PATHS)[] = [
  "LANDING",
  "REGISTER",
  "LOGIN",
];

/**
 * Determines if a given path is a public route.
 *
 * This function checks if the provided path corresponds to one of the public routes
 * defined in the PUBLIC_ROUTES array. It does this by first finding the key in the PATHS
 * object that matches the given path. If a matching key is found, it checks if this key
 * is included in the PUBLIC_ROUTES array.
 *
 * @param path - The path to check.
 * @returns True if the path is a public route, false otherwise.
 */
export const isPublicRoute = (path: string): boolean => {
  // Find the key in PATHS that matches the given path
  const pathKey = Object.keys(PATHS).find(
    (key) => PATHS[key as keyof typeof PATHS] === path,
  );

  // Check if the found key is in the list of public routes
  return pathKey
    ? PUBLIC_ROUTES.includes(pathKey as keyof typeof PATHS)
    : false;
};

export const ROUTES: RouteConfig[] = [
  {
    path: "/",
    element: (
      <Suspense fallback={<div>Loading layout...</div>}>
        <Layout />
      </Suspense>
    ),
    children: [
      {
        path: PATHS.LANDING,
        element: (
          <Suspense fallback={<div>Loading landing...</div>}>
            <Landing />
          </Suspense>
        ),
      },
      {
        path: PATHS.LOGIN,
        element: (
          <Suspense fallback={<div>Loading login...</div>}>
            <Login />
          </Suspense>
        ),
      },
      {
        path: PATHS.REGISTER,
        element: (
          <Suspense fallback={<div>Loading register...</div>}>
            <Register />
          </Suspense>
        ),
      },
      {
        path: PATHS.HOME,
        element: (
          <Suspense fallback={<div>Loading home...</div>}>
            <Home />
          </Suspense>
        ),
      },
      {
        path: PATHS.CREATE,
        element: (
          <Suspense fallback={<div>Loading editor...</div>}>
            <Editor />
          </Suspense>
        ),
      },
      {
        path: PATHS.PREVIEW,
        element: (
          <Suspense fallback={<div>Loading preview...</div>}>
            <div>Preview</div>
          </Suspense>
        ),
      },
      {
        path: PATHS.RESULTS,
        element: (
          <Suspense fallback={<div>Loading results...</div>}>
            <FormResults />
          </Suspense>
        ),
      },
      {
        path: PATHS.INTEGRATIONS,
        element: (
          <Suspense fallback={<div>Loading Integrations...</div>}>
            <div>Integrations</div>
          </Suspense>
        ),
      },
    ],
  },
];
