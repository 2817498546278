import { PATHS, isPublicRoute } from "@/common/routes";
import { useNamedLocation } from "@/hooks/use-named-location";
import { toggleIsEditor } from "@/services/redux/slices/editorSlice";
import { AppDispatch, RootState } from "@/services/redux/store";
import { useDispatch, useSelector } from "react-redux";
import { Link, generatePath, useNavigate } from "react-router-dom";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "./ui/breadcrumb";
import { Button } from "./ui/button";
import { Card } from "./ui/card";
import { Label } from "./ui/label";
import { Logo } from "./ui/logo";
import NavbarAvatar from "./ui/navbar-avatar";
import { Switch } from "./ui/switch";

export default function Navbar() {
  const currentPathKey = useNamedLocation();

  if (currentPathKey && isPublicRoute(PATHS[currentPathKey])) {
    return null; // Do not render the navbar on public routes
  }

  const determineVariant = (key: keyof typeof PATHS) => {
    return currentPathKey === key ? "secondary" : "ghost";
  };

  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const isEditor = useSelector((state: RootState) => state.editor.isEditor);

  const selectedFormId = useSelector(
    (state: RootState) => state.selectedFormId.formId,
  );

  const handleKeyDown = (event: { key: string }) => {
    if (event.key === "Enter" || event.key === " ") {
      navigate(PATHS.HOME);
    }
  };

  return selectedFormId ? (
    <Card className="p-2 flex items-center justify-between min-h-14 max-h-14">
      <div className="flex items-center gap-2">
        <Link to={PATHS.HOME} className="cursor-pointer">
          <Logo />
        </Link>
        <Breadcrumb>
          <BreadcrumbList>
            <BreadcrumbItem>
              <BreadcrumbLink asChild>
                <Link to={PATHS.HOME}>My Workspace</Link>
              </BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbSeparator />
            <BreadcrumbItem>
              <BreadcrumbPage>My Form</BreadcrumbPage>
            </BreadcrumbItem>
          </BreadcrumbList>
        </Breadcrumb>
      </div>
      <div className="flex-grow flex justify-center items-center space-x-1">
        <Link
          to={generatePath(PATHS.CREATE, {
            selectedFormId: selectedFormId,
          })}
        >
          <Button variant={determineVariant("CREATE")}>Create</Button>
        </Link>
        <Link
          to={generatePath(PATHS.PREVIEW, {
            selectedFormId: selectedFormId,
          })}
        >
          <Button variant={determineVariant("PREVIEW")}>Preview</Button>
        </Link>
        <Link
          to={generatePath(PATHS.RESULTS, {
            selectedFormId: selectedFormId,
          })}
        >
          <Button variant={determineVariant("RESULTS")}>Results</Button>
        </Link>
      </div>

      <div className="flex items-center gap-2">
        <div className="flex items-center space-x-2">
          <Switch
            id="editor-mode"
            checked={isEditor}
            onCheckedChange={() => dispatch(toggleIsEditor())}
          />
          <Label htmlFor="editor-mode">Editor Mode</Label>
        </div>
        <Button size={"sm"} variant={"outline"}>
          Share
        </Button>
        <Button size={"sm"}>Publish</Button>
      </div>
    </Card>
  ) : (
    <Card className="p-2 flex items-center justify-between min-h-14 max-h-14">
      <Link to={PATHS.HOME} className="cursor-pointer">
        <Logo />
      </Link>
      <div className="flex-grow flex justify-center items-center space-x-1">
        <Link to={PATHS.HOME}>
          <Button variant={determineVariant("HOME")}>Workspace</Button>
        </Link>
        <Link to={PATHS.INTEGRATIONS}>
          <Button variant={determineVariant("INTEGRATIONS")}>
            Integrations
          </Button>
        </Link>
      </div>
      <div className="flex items-center gap-2">
        <Button size={"sm"}>Create Form</Button>
        <NavbarAvatar />
      </div>
    </Card>
  );
}
