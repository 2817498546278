import { FormContent } from "@/api/getContentByFormId";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface SelectedFormContentSliceProps extends FormContent {}

const initialState: SelectedFormContentSliceProps = {
  contentId: null,
  formId: null,
  order: 1,
  title: null,
  description: null,
  contentType: "short-text",
  options: null,
  answerChoices: null,
};

const selectedFormContentSlice = createSlice({
  name: "selectedContent",
  initialState,
  reducers: {
    selectFormContent(
      state,
      action: PayloadAction<SelectedFormContentSliceProps>,
    ) {
      state.contentId = action.payload.contentId;
      state.formId = action.payload.formId;
      state.order = action.payload.order;
      state.title = action.payload.title;
      state.description = action.payload.description;
      state.contentType = action.payload.contentType;
      state.options = action.payload.options;
      state.answerChoices = action.payload.answerChoices;
    },
    clearSelectedFormContent(state) {
      Object.assign(state, initialState);
    },
  },
});

export const { selectFormContent, clearSelectedFormContent } =
  selectedFormContentSlice.actions;

export default selectedFormContentSlice.reducer;
