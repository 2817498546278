import { FormMessage } from "@/components/ui/form";
import { UseFormReturn } from "react-hook-form";

interface RootFormMessageProps {
  form: UseFormReturn<any>;
}

const RootFormMessage: React.FC<RootFormMessageProps> = ({ form }) => {
  const { errors } = form.formState;
  if (!errors.root) return null;

  return <FormMessage>{errors.root.message}</FormMessage>;
};

export default RootFormMessage;
