import axios from "axios";

const lunaAxios = axios.create({
  baseURL: "https://backend.lunaform.ai/v1",
});

export class BackendError extends Error {
  constructor(message: string) {
    super(message);
    this.name = "BackendError";
  }
}

interface ErrorObject {
  errors: {
    target: Record<string, string>;
    value: string;
    property: string;
    children: any[];
    constraints: Record<string, string>;
  }[];
}

export class BackendFormError extends BackendError {
  public fieldErrors: Map<string, string[]>;

  constructor(errorObject: ErrorObject) {
    const fieldErrors: Map<string, string[]> = new Map();

    errorObject.errors.forEach((error) => {
      const field = error.property;
      const constraints = Object.values(error.constraints);
      fieldErrors.set(field, constraints);
    });

    const message = Array.from(fieldErrors.entries())
      .map(([field, errors]) => `Error in ${field}: ${errors.join(", ")}`)
      .join(". ");

    super(message);
    this.fieldErrors = fieldErrors;
  }
}

lunaAxios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response?.data?.errors) {
      return Promise.reject(new BackendFormError(error.response.data));
    } else {
      const errorMessage =
        error.message === "Network Error"
          ? "Unable to connect to the backend. Please check your internet connection."
          : error.response?.data?.error || error.message;
      return Promise.reject(new BackendError(errorMessage));
    }
  },
);

export default lunaAxios;
