import {
  clearSelectedForm,
  selectForm,
} from "@/services/redux/slices/selectedFormIdSlice";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

/**
 * FormIdWatcher component
 *
 * This component listens for changes in the route and checks if the route contains
 * a `selectedFormId` parameter. If it detects a change in the `selectedFormId`, it
 * dispatches an action to update the selected form ID in the Redux store.
 *
 * The component is rendered at the top level of the application to ensure it runs
 * whenever the route changes.
 */
const FormIdWatcher: React.FC = () => {
  const { selectedFormId } = useParams<{ selectedFormId: string }>();
  const dispatch = useDispatch();

  useEffect(() => {
    if (selectedFormId) {
      dispatch(selectForm(selectedFormId));
    } else {
      dispatch(clearSelectedForm());
    }
  }, [selectedFormId, dispatch]);
  return null;
};

export default FormIdWatcher;
