import { removeTokens } from "@/services/redux/slices/authenticationSlice";
import React from "react";
import { useDispatch } from "react-redux";
import { Avatar, AvatarFallback, AvatarImage } from "./avatar";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "./dropdown-menu";

/**
 * NavbarAvatar component
 *
 * This component displays an avatar with a dropdown menu. When the avatar is clicked,
 * a dropdown appears with an option to log out. Logging out will remove the authentication
 * tokens from the Redux store and local storage.
 */
const NavbarAvatar: React.FC = () => {
  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(removeTokens());
  };

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Avatar className="h-8 w-8 cursor-pointer">
          <AvatarImage src="https://github.com/shadcn.png" />
          <AvatarFallback>LF</AvatarFallback>
        </Avatar>
      </DropdownMenuTrigger>
      <DropdownMenuContent>
        <DropdownMenuItem onSelect={handleLogout} className="cursor-pointer">
          Log Out
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export default NavbarAvatar;
