import { PATHS } from "@/common/routes";
import { Button } from "@/components/ui/button";
import { Link } from "react-router-dom";

function Landing() {
  return (
    <div>
      <p>Landing page</p>
      <p>
        <Link to={PATHS.REGISTER}>
          <Button>Register</Button>
        </Link>
      </p>
      <p>
        <Link to={PATHS.LOGIN}>
          <Button>Login</Button>
        </Link>
      </p>
      <p>
        <Link to={PATHS.HOME}>
          <Button>Home (if u r logged in)</Button>
        </Link>
      </p>
    </div>
  );
}

export default Landing;
