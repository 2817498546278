import { useLocation } from "react-router-dom";
import { matchPath } from "react-router";
import { PATHS } from "@/common/routes";

/**
 * Custom hook to determine the current named route.
 *
 * This hook uses the current location and matches it against predefined paths
 * to determine which route the user is currently on. It accounts for dynamic
 * segments in the path (e.g., :formId).
 *
 * @returns The key of the current path from PATHS, or null if no match is found.
 */
export const useNamedLocation = (): keyof typeof PATHS | null => {
  const location = useLocation();

  // Iterate over the PATHS to find a match
  for (const key in PATHS) {
    if (matchPath(PATHS[key as keyof typeof PATHS], location.pathname)) {
      return key as keyof typeof PATHS;
    }
  }

  return null;
};
