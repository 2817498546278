import ProtectedRoute from "@/components/no-ui-utils/protected-route";
import React, { Suspense } from "react";
import { Route, HashRouter as Router, Routes } from "react-router-dom";
import { isPublicRoute, ROUTES } from "../../common/routes";

const AppRouter: React.FC = () => {
  return (
    <Router>
      <Suspense fallback={<div>Loading application...</div>}>
        <Routes>
          {ROUTES.map((route, index) => (
            <Route key={index} path={route.path} element={route.element}>
              {route.children?.map((childrenRoute, childIndex) => {
                const isPublic = isPublicRoute(childrenRoute.path);

                return (
                  <Route
                    key={childIndex}
                    path={childrenRoute.path}
                    element={
                      isPublic ? (
                        childrenRoute.element
                      ) : (
                        <ProtectedRoute>{childrenRoute.element}</ProtectedRoute>
                      )
                    }
                  />
                );
              })}
            </Route>
          ))}
        </Routes>
      </Suspense>
    </Router>
  );
};

export default AppRouter;
