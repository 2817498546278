import lunaAxios from "./lunaAxios";

interface RegisterLocalRequest {
  email: string;
  password: string;
}

interface RegisterLocalResponse {
  data: {
    accessToken: string;
    refreshToken: string;
  };
}

interface LoginLocalRequest {
  email: string;
  password: string;
}

export interface LoginLocalResponse {
  data: {
    accessToken: string;
    refreshToken: string;
  };
}

export const loginLocal = async (
  body: LoginLocalRequest,
): Promise<LoginLocalResponse> => {
  const response = await lunaAxios.post<LoginLocalResponse>(
    "/me/login-local",
    body,
  );
  return response.data;
};

export const registerLocal = async (
  body: RegisterLocalRequest,
): Promise<RegisterLocalResponse> => {
  const response = await lunaAxios.post<RegisterLocalResponse>(
    "/me/register-local",
    body,
  );
  return response.data;
};
