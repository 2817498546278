import { Link } from "react-router-dom";

interface LinkTextProps {
  to: string;
  children: React.ReactNode;
}

const LinkText: React.FC<LinkTextProps> = ({ to, children }) => (
  <Link to={to} className="underline">
    {children}
  </Link>
);

export default LinkText;
