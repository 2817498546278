import { LoginLocalResponse, registerLocal } from "@/api/authentication";
import { PATHS } from "@/common/routes";
import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import LinkText from "@/components/ui/link-text";
import RootFormMessage from "@/components/ui/root-form-message";
import { setTokens } from "@/services/redux/slices/authenticationSlice";
import { handleFormError } from "@/utils";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

interface RegisterFormData {
  email: string;
  password: string;
  repeatPassword: string;
}

function Register() {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const form = useForm<RegisterFormData>({
    defaultValues: {
      email: "",
      password: "",
      repeatPassword: "",
    },
  });

  const passwordValidation = (value: string): string | true => {
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\W).{6,}$/;
    return passwordRegex.test(value) || "Password does not meet requirements";
  };
  const handleSubmit = (data: RegisterFormData): void => {
    form.clearErrors();
    if (data.password !== data.repeatPassword) {
      form.setError("repeatPassword", {
        type: "manual",
        message: "Passwords do not match",
      });
      return;
    }
    setIsSubmitting(true);
    registerLocal({ email: data.email, password: data.password })
      .then(handleRegistrationSuccess)
      .catch((error) => handleFormError(error, form))
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  // Temporary: Directly log in the user after registration since we don't have a "confirm email" page yet
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleRegistrationSuccess = (response: LoginLocalResponse): void => {
    dispatch(setTokens(response.data));
    navigate(PATHS.HOME);
  };

  const validateRepeatPassword = (value: string): string | true => {
    return value === form.getValues("password") || "Passwords do not match";
  };
  return (
    <Card className="max-w-lg mx-auto p-8">
      <CardHeader>
        <CardTitle>Sign Up</CardTitle>
        <CardDescription>
          Enter your information to create an account
        </CardDescription>
      </CardHeader>
      <CardContent>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(handleSubmit)}>
            <div className="space-y-6">
              <FormField
                control={form.control}
                name="email"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Email</FormLabel>
                    <FormMessage />
                    <FormControl>
                      <Input
                        type="text"
                        {...field}
                        required
                        disabled={isSubmitting}
                      />
                    </FormControl>
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="password"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Password</FormLabel>
                    <FormDescription>
                      Password must be at least 6 characters long, contain one
                      uppercase letter, one lowercase letter, and one special
                      character.
                    </FormDescription>
                    <FormMessage />
                    <FormControl>
                      <Input type="password" {...field} required />
                    </FormControl>
                  </FormItem>
                )}
                rules={{
                  validate: passwordValidation,
                }}
              />
              <FormField
                control={form.control}
                name="repeatPassword"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Repeat Password</FormLabel>
                    <FormMessage />
                    <FormControl>
                      <Input type="password" {...field} required />
                    </FormControl>
                  </FormItem>
                )}
                rules={{
                  validate: validateRepeatPassword,
                }}
              />
              <RootFormMessage form={form} />
              <Button
                type="submit"
                className="w-full mt-6"
                disabled={isSubmitting}
              >
                Create an Account
              </Button>
              <Button
                type="button"
                variant="outline"
                className="w-full mt-2"
                onClick={() => alert("This isn't yet implemented")}
                disabled={isSubmitting}
              >
                Sign Up with Google
              </Button>
              <p className="text-sm mt-4 text-center">
                Already have an account?{" "}
                <LinkText to={PATHS.LOGIN}>Login</LinkText>
              </p>
            </div>
          </form>
        </Form>
      </CardContent>
    </Card>
  );
}

export default Register;
