import { FC } from "react";

import { ThemeProvider } from "./components/no-ui-utils/theme-provider";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import AppRouter from "./services/router/AppRouter";
import "./styles/globals.css";

const App: FC = () => {
  const queryClient = new QueryClient();

  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider defaultTheme="dark" storageKey="lunaform-ui-theme">
        <div className="App">
          <AppRouter />
        </div>
      </ThemeProvider>
    </QueryClientProvider>
  );
};

export default App;
