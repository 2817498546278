import { loginLocal, LoginLocalResponse } from "@/api/authentication";
import { PATHS } from "@/common/routes";
import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import LinkText from "@/components/ui/link-text";
import RootFormMessage from "@/components/ui/root-form-message";
import { setTokens } from "@/services/redux/slices/authenticationSlice";
import { handleFormError } from "@/utils";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

interface LoginFormData {
  email: string;
  password: string;
}

function Login() {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const form = useForm<LoginFormData>({
    defaultValues: {
      email: "",
      password: "",
    },
  });

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = (data: LoginFormData): void => {
    form.clearErrors();
    setIsSubmitting(true);
    loginLocal({ email: data.email, password: data.password })
      .then(handleLoginSuccess)
      .catch((error) => handleFormError(error, form))
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const handleLoginSuccess = (response: LoginLocalResponse): void => {
    dispatch(setTokens(response.data));
    navigate(PATHS.HOME);
  };

  return (
    <Card className="max-w-lg mx-auto p-8">
      <CardHeader>
        <CardTitle>Login</CardTitle>
        <CardDescription>
          Enter your email below to login to your account.
        </CardDescription>
      </CardHeader>
      <CardContent>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(handleSubmit)}>
            <div className="space-y-6">
              <FormField
                control={form.control}
                name="email"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Email</FormLabel>
                    <FormMessage />
                    <FormControl>
                      <Input
                        type="text"
                        {...field}
                        required
                        disabled={isSubmitting}
                      />
                    </FormControl>
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="password"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel className="flex justify-between">
                      Password
                      <LinkText to="#">Forgot your password?</LinkText>
                    </FormLabel>
                    <FormMessage />
                    <FormControl>
                      <Input
                        type="password"
                        {...field}
                        required
                        disabled={isSubmitting}
                      />
                    </FormControl>
                  </FormItem>
                )}
              />
              <RootFormMessage form={form} />
              <Button
                type="submit"
                className="w-full mt-6"
                disabled={isSubmitting}
              >
                Login
              </Button>
              <Button
                type="button"
                variant="outline"
                className="w-full mt-2"
                onClick={() => alert("This isn't yet implemented")}
                disabled={isSubmitting}
              >
                Login with Google
              </Button>
              <p className="text-sm mt-4 text-center">
                Don't have an account?{" "}
                <LinkText to={PATHS.REGISTER}>Sign Up</LinkText>
              </p>
            </div>
          </form>
        </Form>
      </CardContent>
    </Card>
  );
}

export default Login;
