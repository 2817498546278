interface LogoProps {
  /* @default 32. Width is dynamically calculated with respect to the ascpect ratio */
  height?: number;
  /* @default "iconOnly" */
  variant?: "iconOnly" | "full";
  /* @default "light" */
  color?: "light" | "dark" | "accent";
}

function Logo({
  height = 32,
  variant = "iconOnly",
  color = "light",
}: LogoProps) {
  const width = height * (variant === "iconOnly" ? 1 : 7.22);
  let fill: string;
  switch (color) {
    case "dark":
      fill = "#2B2B2B";
      break;
    case "accent":
      fill = "#00B1D8";
      break;
    default:
      fill = "white";
      break;
  }

  if (variant === "iconOnly") {
    return (
      <svg
        width={width}
        height={height}
        viewBox="0 0 435 434"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          width="433.795"
          height="433.795"
          transform="translate(0.986816 0.211426)"
          fill="#151515"
        />
        <g clipPath="url(#clip0_4_634)">
          <path
            d="M294.48 183.921C295.887 183.921 297.028 185.062 297.028 186.469C297.028 186.75 296.982 187.024 296.898 187.277C296.898 187.274 296.897 187.28 296.898 187.277C296.559 188.286 295.604 189.018 294.48 189.018C293.357 189.018 292.403 188.288 292.063 187.28L292.062 187.277C292.063 187.28 292.062 187.274 292.062 187.277C291.978 187.024 291.932 186.75 291.932 186.469C291.932 185.062 293.073 183.921 294.48 183.921Z"
            fill={fill}
          />
          <path
            d="M294.48 98.1138C295.887 98.1138 297.028 96.973 297.028 95.5657C297.028 95.2849 296.982 95.0109 296.898 94.7583C296.898 94.7609 296.897 94.7556 296.898 94.7583C296.897 94.7542 296.895 94.7501 296.894 94.746C296.552 93.7427 295.599 93.0176 294.48 93.0176C294.092 93.0176 293.725 93.1042 293.396 93.2592C293.385 93.2644 293.374 93.2696 293.363 93.275C292.754 93.5724 292.28 94.1088 292.062 94.7583C292.062 94.7609 292.063 94.7556 292.062 94.7583C291.978 95.0109 291.932 95.2849 291.932 95.5657C291.932 96.973 293.073 98.1138 294.48 98.1138Z"
            fill={fill}
          />
          <path
            d="M337.384 141.018C337.384 139.61 338.525 138.469 339.932 138.469C340.213 138.469 340.483 138.515 340.735 138.599C340.738 138.599 340.741 138.6 340.743 138.601C341.753 138.94 342.48 139.894 342.48 141.018C342.48 141.298 342.435 141.569 342.351 141.822C342.099 142.58 341.5 143.18 340.743 143.434C340.741 143.435 340.738 143.436 340.735 143.436C340.483 143.52 340.213 143.566 339.932 143.566C338.525 143.566 337.384 142.425 337.384 141.018Z"
            fill={fill}
          />
          <path
            d="M251.576 141.018C251.576 139.61 250.435 138.469 249.028 138.469C248.747 138.469 248.477 138.515 248.225 138.599C248.222 138.6 248.219 138.6 248.217 138.601C247.207 138.94 246.48 139.894 246.48 141.018C246.48 141.405 246.567 141.773 246.722 142.102C246.727 142.113 246.732 142.124 246.738 142.135C247.035 142.744 247.567 143.216 248.217 143.434C248.219 143.435 248.222 143.436 248.225 143.436C248.477 143.52 248.747 143.566 249.028 143.566C250.435 143.566 251.576 142.425 251.576 141.018Z"
            fill={fill}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M342.48 141.018C342.48 141.298 342.435 141.569 342.351 141.822C342.099 142.58 341.5 143.18 340.743 143.434L340.735 143.436C319.579 149.448 302.908 166.12 296.898 187.277C296.559 188.286 295.604 189.018 294.48 189.018C293.353 189.018 292.399 188.29 292.062 187.277L292.063 187.28C287.001 169.457 274.373 154.816 257.872 147.05C254.781 145.596 251.555 144.382 248.217 143.434C247.567 143.216 247.035 142.744 246.738 142.135C246.732 142.124 246.727 142.113 246.722 142.102C246.567 141.773 246.48 141.405 246.48 141.018C246.48 139.894 247.207 138.94 248.217 138.601C269.377 132.591 286.051 115.918 292.062 94.7583C292.279 94.1053 292.752 93.5734 293.363 93.275C293.374 93.2696 293.385 93.2644 293.396 93.2592C293.725 93.1042 294.092 93.0176 294.48 93.0176C295.599 93.0176 296.552 93.7427 296.894 94.746C296.895 94.7501 296.897 94.7542 296.898 94.7583L296.894 94.746C302.902 115.912 319.579 132.59 340.743 138.601C341.753 138.94 342.48 139.894 342.48 141.018ZM296.894 94.746C296.893 94.7424 296.892 94.7387 296.891 94.735L296.894 94.746Z"
            fill={fill}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M378 217.5C378 305.866 306.366 377.5 218 377.5C129.634 377.5 58 305.866 58 217.5C58 129.134 129.634 57.5 218 57.5C234.398 57.5 250.22 59.9669 265.115 64.5497C237.334 77.0926 218 105.038 218 137.5C218 181.683 253.817 217.5 298 217.5C330.462 217.5 358.407 198.166 370.95 170.385C375.533 185.28 378 201.102 378 217.5Z"
            fill={fill}
          />
        </g>
        <defs>
          <clipPath id="clip0_4_634">
            <rect
              width="433.795"
              height="433.795"
              fill={fill}
              transform="translate(0.986816 0.211426)"
            />
          </clipPath>
        </defs>
      </svg>
    );
  }

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 722 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M125.894 0.00012207H145.99V100H125.894L125.894 0.00012207Z"
        fill={fill}
      />
      <path
        d="M158.858 65.2733V28.135H178.955V64.6302C178.955 69.8821 180.08 74.1693 182.331 77.492C184.689 80.8146 188.226 82.4759 192.942 82.4759C197.765 82.4759 201.195 80.8146 203.231 77.492C205.268 74.1693 206.286 69.8821 206.286 64.6302V28.135H226.382V100H206.286V94.2122C204.249 96.0343 201.945 97.4812 199.373 98.553C196.908 99.5177 194.067 100 190.852 100C185.814 100 181.313 99.1425 177.347 97.4276C173.381 95.7127 170.005 93.3548 167.218 90.3537C164.539 87.2454 162.449 83.6013 160.948 79.4212C159.555 75.134 158.858 70.418 158.858 65.2733Z"
        fill={fill}
      />
      <path
        d="M306.762 61.0932V100H286.665V61.7363C286.665 56.4845 285.487 52.1972 283.129 48.8746C280.878 45.552 277.394 43.8907 272.678 43.8907C267.855 43.8907 264.425 45.552 262.389 48.8746C260.353 52.1972 259.334 56.4845 259.334 61.7363V100H239.238V28.135H259.334V32.1543C261.371 30.3323 263.622 28.9389 266.087 27.9743C268.659 26.9025 271.553 26.3666 274.768 26.3666C279.806 26.3666 284.308 27.224 288.273 28.9389C292.239 30.6538 295.562 33.0654 298.241 36.1736C301.028 39.1747 303.118 42.8189 304.511 47.1061C306.012 51.2862 306.762 55.9485 306.762 61.0932Z"
        fill={fill}
      />
      <path
        d="M397.431 28.135V100H377.495V93.5691C374.494 95.6056 371.225 97.2133 367.688 98.3923C364.151 99.4641 360.4 100 356.434 100C351.397 100 346.627 99.0354 342.125 97.1061C337.624 95.1768 333.712 92.5509 330.389 89.2283C327.067 85.9057 324.441 82.0472 322.511 77.6527C320.582 73.1511 319.617 68.328 319.617 63.1833C319.617 58.0386 320.582 53.269 322.511 48.8746C324.441 44.373 327.067 40.4609 330.389 37.1383C333.712 33.8156 337.624 31.1897 342.125 29.2605C346.627 27.3312 351.397 26.3666 356.434 26.3666C360.4 26.3666 364.151 26.9561 367.688 28.135C371.225 29.2069 374.494 30.761 377.495 32.7974V28.135H397.431ZM358.524 82.1543C361.097 82.1543 363.508 81.672 365.759 80.7074C368.117 79.6356 370.153 78.2422 371.868 76.5273C373.583 74.8124 374.923 72.8296 375.888 70.5788C376.959 68.2208 377.495 65.7556 377.495 63.1833C377.495 60.6109 376.959 58.1994 375.888 55.9486C374.923 53.5906 373.583 51.5541 371.868 49.8392C370.153 48.1243 368.117 46.7846 365.759 45.8199C363.508 44.7481 361.097 44.2122 358.524 44.2122C355.952 44.2122 353.487 44.7481 351.129 45.8199C348.878 46.7846 346.895 48.1243 345.18 49.8392C343.465 51.5541 342.125 53.5906 341.161 55.9486C340.196 58.1994 339.714 60.6109 339.714 63.1833C339.714 65.7556 340.196 68.2208 341.161 70.5788C342.125 72.8296 343.465 74.8124 345.18 76.5273C346.895 78.2422 348.878 79.6356 351.129 80.7074C353.487 81.672 355.952 82.1543 358.524 82.1543Z"
        fill={fill}
      />
      <path
        d="M442.996 28.135H459.073V46.1415H442.996V100H422.899V46.1415H410.359V28.135H422.899V27.8135C422.899 23.955 423.596 20.3644 424.989 17.0418C426.49 13.612 428.526 10.6645 431.099 8.19936C433.671 5.62701 436.619 3.64416 439.941 2.2508C443.371 0.750268 447.015 0 450.874 0H459.073V17.8457H453.768C450.874 17.8457 448.409 18.8639 446.372 20.9003C444.336 22.9368 443.21 25.3483 442.996 28.135Z"
        fill={fill}
      />
      <path
        d="M503.848 26.3666C508.993 26.3666 513.762 27.3312 518.157 29.2605C522.658 31.1897 526.57 33.8156 529.893 37.1383C533.323 40.4609 536.002 44.373 537.932 48.8746C539.861 53.269 540.825 58.0386 540.825 63.1833C540.825 68.328 539.861 73.1511 537.932 77.6527C536.002 82.0472 533.323 85.9057 529.893 89.2283C526.57 92.5509 522.658 95.1768 518.157 97.1061C513.762 99.0354 508.993 100 503.848 100C498.81 100 494.041 99.0354 489.539 97.1061C485.038 95.1768 481.126 92.5509 477.803 89.2283C474.48 85.9057 471.854 82.0472 469.925 77.6527C467.996 73.1511 467.031 68.328 467.031 63.1833C467.031 58.0386 467.996 53.269 469.925 48.8746C471.854 44.373 474.48 40.4609 477.803 37.1383C481.126 33.8156 485.038 31.1897 489.539 29.2605C494.041 27.3312 498.81 26.3666 503.848 26.3666ZM503.848 82.1543C506.42 82.1543 508.832 81.672 511.083 80.7074C513.441 79.6356 515.477 78.2422 517.192 76.5273C518.907 74.8124 520.247 72.8296 521.211 70.5788C522.283 68.2208 522.819 65.7556 522.819 63.1833C522.819 60.6109 522.283 58.1994 521.211 55.9486C520.247 53.5906 518.907 51.5541 517.192 49.8392C515.477 48.1243 513.441 46.7846 511.083 45.8199C508.832 44.7481 506.42 44.2122 503.848 44.2122C501.276 44.2122 498.81 44.7481 496.452 45.8199C494.202 46.7846 492.219 48.1243 490.504 49.8392C488.789 51.5541 487.449 53.5906 486.485 55.9486C485.52 58.1994 485.038 60.6109 485.038 63.1833C485.038 65.7556 485.52 68.2208 486.485 70.5788C487.449 72.8296 488.789 74.8124 490.504 76.5273C492.219 78.2422 494.202 79.6356 496.452 80.7074C498.81 81.672 501.276 82.1543 503.848 82.1543Z"
        fill={fill}
      />
      <path
        d="M594.688 46.1415C587.614 46.1415 582.362 47.5348 578.932 50.3215C575.502 53.1082 573.787 56.6988 573.787 61.0932V100H553.691V28.135H573.787V32.7974C576.788 30.761 580.058 29.2069 583.594 28.135C587.131 26.9561 590.829 26.3666 594.688 26.3666V46.1415Z"
        fill={fill}
      />
      <path
        d="M721.042 61.0932V100H701.106V61.7363C701.106 56.4845 700.195 52.2508 698.373 49.0354C696.551 45.8199 693.014 44.2122 687.762 44.2122C682.51 44.2122 678.973 45.8199 677.151 49.0354C675.329 52.2508 674.418 56.4845 674.418 61.7363V100H654.321V61.7363C654.321 56.4845 653.41 52.2508 651.588 49.0354C649.766 45.8199 646.229 44.2122 640.977 44.2122C635.726 44.2122 632.189 45.8199 630.366 49.0354C628.544 52.2508 627.633 56.4845 627.633 61.7363V100H607.537V28.135H627.633V32.3151C629.991 30.493 632.564 29.0461 635.35 27.9743C638.137 26.9025 641.138 26.3666 644.354 26.3666C650.141 26.3666 655.018 27.4384 658.984 29.582C662.95 31.6184 666.165 34.5123 668.63 38.2637C671.417 34.6195 674.686 31.7256 678.437 29.582C682.189 27.4384 686.422 26.3666 691.138 26.3666C696.283 26.3666 700.731 27.224 704.482 28.9389C708.234 30.6538 711.342 33.0654 713.807 36.1736C716.272 39.1747 718.094 42.8189 719.273 47.1061C720.452 51.2862 721.042 55.9485 721.042 61.0932Z"
        fill={fill}
      />
      <path
        d="M74.5463 39.5072C74.9861 39.5072 75.3426 39.8637 75.3426 40.3034C75.3426 40.3912 75.328 40.4768 75.3018 40.5558C75.196 40.8712 74.8974 41.0997 74.5463 41.0997C74.1955 41.0997 73.8972 40.8717 73.791 40.5567L73.7908 40.5558C73.7646 40.4768 73.75 40.3912 73.75 40.3034C73.75 39.8637 74.1065 39.5072 74.5463 39.5072Z"
        fill={fill}
      />
      <path
        d="M74.5463 12.6923C74.9861 12.6923 75.3426 12.3358 75.3426 11.896C75.3426 11.8083 75.328 11.7226 75.3018 11.6437L75.3007 11.6399C75.1938 11.3263 74.896 11.0997 74.5463 11.0997C74.4251 11.0997 74.3102 11.1268 74.2074 11.1752C74.204 11.1768 74.2005 11.1785 74.1971 11.1802C74.0069 11.2731 73.8589 11.4407 73.7908 11.6437C73.7646 11.7226 73.75 11.8083 73.75 11.896C73.75 12.3358 74.1065 12.6923 74.5463 12.6923Z"
        fill={fill}
      />
      <path
        d="M87.9537 26.0997C87.9537 25.6599 88.3102 25.3034 88.75 25.3034C88.8377 25.3034 88.9222 25.3176 89.0011 25.3438L89.0036 25.3445C89.319 25.4504 89.5463 25.7486 89.5463 26.0997C89.5463 26.1875 89.5321 26.272 89.5059 26.351C89.4272 26.5879 89.2402 26.7755 89.0036 26.8549L89.0011 26.8556C88.9222 26.8818 88.8377 26.896 88.75 26.896C88.3102 26.896 87.9537 26.5395 87.9537 26.0997Z"
        fill={fill}
      />
      <path
        d="M61.1389 26.0997C61.1389 25.6599 60.7824 25.3034 60.3426 25.3034C60.2548 25.3034 60.1704 25.3176 60.0915 25.3438L60.089 25.3445C59.7735 25.4504 59.5463 25.7486 59.5463 26.0997C59.5463 26.2209 59.5734 26.3358 59.6218 26.4386C59.6234 26.4421 59.6251 26.4455 59.6268 26.449C59.7197 26.6391 59.8861 26.7868 60.089 26.8549L60.0915 26.8556C60.1704 26.8818 60.2548 26.896 60.3426 26.896C60.7824 26.896 61.1389 26.5395 61.1389 26.0997Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M89.5463 26.0997C89.5463 26.1875 89.5321 26.272 89.5059 26.351C89.4272 26.5879 89.2402 26.7755 89.0036 26.8549L89.0011 26.8556C82.3896 28.7342 77.18 33.9441 75.3018 40.5558C75.196 40.8712 74.8974 41.0997 74.5463 41.0997C74.1943 41.0997 73.8959 40.8725 73.7908 40.5558L73.791 40.5567C72.2091 34.9869 68.263 30.4118 63.1062 27.9849C62.1404 27.5303 61.1322 27.1512 60.089 26.8549C59.8861 26.7868 59.7197 26.6391 59.6268 26.449C59.6251 26.4455 59.6234 26.4421 59.6218 26.4386C59.5734 26.3358 59.5463 26.2209 59.5463 26.0997C59.5463 25.7486 59.7735 25.4504 60.089 25.3445C66.7017 23.4665 71.9123 18.2561 73.7908 11.6437C73.8585 11.4396 74.0062 11.2734 74.1971 11.1802C74.2005 11.1785 74.204 11.1768 74.2074 11.1752C74.3102 11.1268 74.4251 11.0997 74.5463 11.0997C74.896 11.0997 75.1938 11.3263 75.3007 11.6399C77.1783 18.2541 82.3897 23.4661 89.0036 25.3445C89.319 25.4504 89.5463 25.7486 89.5463 26.0997Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M100.646 50C100.646 77.6142 78.2605 100 50.6462 100C23.032 100 0.646235 77.6142 0.646235 50C0.646235 22.3858 23.032 0 50.6462 0C55.7707 0 60.715 0.770897 65.3697 2.20305C56.6881 6.1227 50.6462 14.8558 50.6462 25C50.6462 38.8071 61.8391 50 75.6462 50C85.7905 50 94.5235 43.9581 98.4432 35.2765C99.8753 39.9312 100.646 44.8756 100.646 50Z"
        fill={fill}
      />
    </svg>
  );
}

export { Logo };
