import { PATHS } from "@/common/routes";
import { selectIsLoggedIn } from "@/services/redux/slices/authenticationSlice";
import React from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import FormIdWatcher from "./form-id-watcher";

interface ProtectedRouteProps {
  children: React.ReactNode;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }) => {
  const isLoggedIn = useSelector(selectIsLoggedIn);

  if (!isLoggedIn) {
    return <Navigate to={PATHS.LOGIN} />;
  }

  return (
    <>
      <FormIdWatcher />
      {children}
    </>
  );
};

export default ProtectedRoute;
